import React, {useState} from "react";
import ReactQuill from 'react-quill';
import EditorToolbar, { modules, formats } from "./EditorToolbar";
import 'react-quill/dist/quill.snow.css';

const TextHtml = (props) => {


  console.log(props);
  const [Content, setContent] = useState(props.content);

  return (
    <div
      style={{
        backgroundColor: props.wrapperBgColor,
        width :props.wrapperWidth,
        height :props.wrapperHeight,
      }} className={`text-html max-w-full relative flex flex-col h-full overflow-auto ${props.className || ""}`}>
      <div className={`container ${props?.containerClass}`}>
        <EditorToolbar />
        <ReactQuill
          theme="snow"
          value={Content}
          onChange={(value) => {
            setContent(value)
            if (props?.getContent){
              props.getContent(value)
            }
          }}
          placeholder={""}
          modules={modules}
          formats={formats}
        />

        {props?.children}

      </div>


      <style jsx>{`
        .ql-toolbar.ql-snow {
          display: flex;
          flex-direction: row;
          flex-wrap: wrap;
          gap: 12px 8px;
        }
        .ql-snow {
          .ql-picker {
            height: auto;
          }
          .ql-picker-label {
            display: flex;
            align-items: center;
          }
        }
        
        .ql-html-overlayContainer {
          .ql-html-popupContainer {
            background: #fff;
            position: absolute;
            top: calc(72px + 50%);
            left: calc(66px + 50%);
            width: 85%; 
            height: 85%;
            transform: translate(calc(-33px + -50%), calc(-36px + -50%));
            border-radius: 16px;
          }
          .ql-html-textContainer {
            height: 100%;
            padding: 10px 16px;
          }
          
          .ql-html-popupTitle {
            font-style: normal;
            margin-bottom: 5px;
          }
          .ql-editor {
            height: 100%;
            max-height: inherit;
          }
          .ql-container {
            background: #efefef;
          }
          
          .ql-html-buttonGroup {
            display: flex;
            align-items: center;
            justify-content: center;
            gap: 8px;
            transform: none;
            bottom: 32px;
            .ql-html-buttonCancel, .ql-html-buttonOk {
              display: inline-flex;
              height: 2.75rem;
              cursor: pointer;
              -webkit-user-select: none;
              user-select: none;
              align-items: center;
              justify-content: center;
              gap: 0.5rem;
              white-space: nowrap;
              border-radius: 0.5rem;
              border-width: 1px;
              --tw-border-opacity: 1;
              border-color: rgb(59 130 246 / var(--tw-border-opacity));
              --tw-bg-opacity: 1;
              background-color: rgb(59 130 246 / var(--tw-bg-opacity));
              padding-left: 18px;
              padding-right: 18px;
              font-size: 1rem;
              line-height: 1.5rem;
              font-weight: 500;
              --tw-text-opacity: 1;
              color: rgb(255 255 255 / var(--tw-text-opacity));
              text-decoration-line: none;
              --tw-shadow: 0px 1px 2px rgba(16, 24, 40, 0.05);
              --tw-shadow-colored: 0px 1px 2px var(--tw-shadow-color);
              box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow);
              transition-property: color, background-color, border-color, text-decoration-color, fill, stroke, opacity, box-shadow, transform, filter, backdrop-filter, -webkit-backdrop-filter;
              transition-timing-function: cubic-bezier(0.4, 0, 0.2, 1);
              transition-duration: 150ms;
            }
            .ql-html-buttonCancel {
              margin: 0;
              --tw-border-opacity: 1;
              border-color: rgb(208 213 221 / var(--tw-border-opacity));
              --tw-bg-opacity: 1;
              background-color: rgb(255 255 255 / var(--tw-bg-opacity));
              --tw-text-opacity: 1;
              color: rgb(102 112 133 / var(--tw-text-opacity));
              outline-width: 0;
            }
            .ql-html-buttonOk {
              
            }
          }
        }
      `}</style>
    </div>
  )
}


export default TextHtml
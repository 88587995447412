/* eslint-disable jsx-a11y/img-redundant-alt */
import React from "react"
import moment from "moment"
import lcfc from "utils/lowerCasedFirstCharacter"

// constants
import { CarType, CarRentalProviderImageUrl } from "constants/index"

// components
import { BorderBox, Icon } from "components"

const ReservationReportsDetailCarRentalModule = ({ reservationData }) => {
  const car = lcfc(reservationData?.DetailResponse?.Car)
  const carExtraOption = car?.carExtraOption?.filter((item) => item?.selected) || []
  const carInsurances = car?.carInsurances?.filter((item) => item?.selected) || []
  const selectedOptions = [...carExtraOption, ...carInsurances]

  return (
    <BorderBox className="p-8">
      <div className="mb-4 flex items-center justify-between"></div>
      <div className="flex items-start justify-start gap-4">
        <div className="relative max-w-[141px]">
          <img
            src={CarRentalProviderImageUrl[car?.carRentalCompany?.code]}
            alt="provider image"
            className="absolute left-0 top-0 h-[21px]"
          />
          <img
            src={car?.carDetail?.imageUrl}
            alt="car image"
            className="w-full max-w-[141px]"
          />
        </div>
        <div>
          <div className="text-lg font-semibold text-gray-800">{CarType[car?.carDetail.type]}</div>
          <div className="text-md text-gray-500">{car?.carDetail.name}</div>
        </div>
      </div>
      <div className="my-6 flex items-start justify-between border-y border-gray-200 py-3">
        <div className="w-1/2 border-r border-gray-200 pr-6">
          <div className="flex items-center justify-between">
            <div className="text-sm font-semibold text-gray-500">Alış Noktası</div>
            <div className="text-sm">{moment(car?.carDetail.pickupDate).format("DD MMM ddd, HH:mm")}</div>
          </div>
          <div className="mt-2 flex items-center gap-3 text-sm text-gray-800">
            <Icon
              name="map-pin"
              size={14}
            />
            {car?.carDetail.pickupCode}
          </div>
        </div>
        <div className="w-1/2 pl-6">
          <div className="flex items-center justify-between">
            <div className="text-sm font-semibold text-gray-500">Teslim Noktası</div>
            <div className="text-sm">{moment(car?.carDetail.returnDate).format("DD MMM ddd, HH:mm")}</div>
          </div>
          <div className="mt-2 flex items-center gap-3 text-sm text-gray-800">
            <Icon
              name="map-pin"
              size={14}
            />
            {car?.carDetail.returnCode}
          </div>
        </div>
      </div>
      {selectedOptions.length > 0 && (
        <div>
          <div className="font-semibold text-gray-500">Ek Hizmetler ve Güvence Paketi</div>
          <div>
            {selectedOptions.map((item, index) => (
              <div
                key={+index}
                className="my-1 flex w-full justify-between text-sm text-gray-800"
              >
                <span>{item.name || item.description}</span>
                <span className="text-gray-800">1 Adet</span>
              </div>
            ))}
          </div>
        </div>
      )}
    </BorderBox>
  )
}

export default ReservationReportsDetailCarRentalModule

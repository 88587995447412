import axios from "axios"
import { logout } from "../utils/logout"
import Cookies from "universal-cookie"
import { getUrl } from "./remoteHost"

const cookies = new Cookies()
const instance = axios.create({
  headers: {
    "Content-Type": "application/json",
    authorization: `Bearer ${
      JSON.parse(localStorage.getItem("user_token"))?.access_token || cookies.get("user_token")?.access_token
    }`,
  },
})

instance.interceptors.request.use((config) => ({
  ...config,
  url: getUrl(config.url),
}))

instance.interceptors.response.use(
  async (response) => {
    if (response.status === 401) await logout()
    return response
  },
  async (error) => {
    const originalRequest = error.config
    if (error.response.status === 401 && !originalRequest._retry) await logout()
    return Promise.reject(error)
  },
)

export default instance

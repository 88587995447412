import instance from "./api_instance"

const API_POST = async (url = "", data = {}, contentType = "application/json", withCredentials = true) =>
  instance({
    method: "POST",
    withCredentials,
    url,
    data,
    headers: {
      "Content-Type": contentType,
    },
  })

const API_PATCH = async (url = "", data = {}, contentType = "application/json", withCredentials = true) =>
  instance({
    method: "PATCH",
    withCredentials,
    url,
    data,
    headers: {
      "Content-Type": contentType,
    },
  })

const API_GET = async (url = "", params = {}, withCredentials = true, headers = {}) =>
  instance({
    method: "GET",
    withCredentials,
    headers: {
      ...headers,
    },
    url,
    params: params,
    paramsSerializer: (params) => {
      let keys = Object.keys(params)
      let queryString = keys.reduce((acc, key) => {
        // if params is not null or empty string
        if (params[key] !== null && params[key] !== "") {
          if (typeof params[key] === "object") {
            // if object write in same key
            acc += params[key].reduce((objectAcc, value) => {
              objectAcc += key + "=" + value + "&"
              return objectAcc
            }, "")
          } else {
            acc += key + "=" + params[key] + "&"
          }
        }
        return acc
      }, "")
      // remove last & and returrn
      return queryString.slice(0, -1)
    },
  })

const API_DELETE = async (url = "", params = {}, withCredentials = true) =>
  instance({
    method: "DELETE",
    withCredentials,
    headers: {
      "Content-Type": "application/x-www-form-urlencoded",
    },
    url,
    params: params,
    paramsSerializer: (params) => {
      let keys = Object.keys(params)
      let queryString = keys.reduce((acc, key) => {
        // if params is not null or empty string
        if (params[key] !== null && params[key] !== "") {
          if (typeof params[key] === "object") {
            // if object write in same key
            acc += params[key].reduce((objectAcc, value) => {
              objectAcc += key + "=" + value + "&"
              return objectAcc
            }, "")
          } else {
            acc += key + "=" + params[key] + "&"
          }
        }
        return acc
      }, "")
      // remove last & and returrn
      return queryString.slice(0, -1)
    },
  })

export { API_POST, API_PATCH, API_GET, API_DELETE }
